/**
 * 工作流管理
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 流程列表查询
    async fetchDefinitionListRequest({ commit, getters }, params) {
      const { definitionListRequest } = getters.getHttps
      const { data } = await definitionListRequest(params)
      return setStoreApiDate(data, commit)
    },
    // 查询任务节点的配置
    async fetchGetTaskConfRequest({ commit, getters }, params) {
      const { getTaskConfRequest } = getters.getHttps
      const { data } = await getTaskConfRequest(params)
      return setStoreApiDate(data, commit)
    },
    // 点击流程按钮执行操作
    async fetchButtonClickRequest({ commit, getters }, params) {
      const { buttonClickRequest } = getters.getHttps
      const { data } = await buttonClickRequest(params)
      return setStoreApiDate(data, commit)
    },
    // 根据任务ID查询提交记录
    async fetchSelectByTaskIdRequest({ commit, getters }, params) {
      const { selectByTaskIdRequest } = getters.getHttps
      const { data } = await selectByTaskIdRequest(params)
      return setStoreApiDate(data, commit)
    },
    // 插入巡检任务内容提交记录
    async fetchDraftTaskRequest({ commit, getters }, params) {
      const { draftTaskRequest } = getters.getHttps
      const { data } = await draftTaskRequest(params)
      return setStoreApiDate(data, commit)
    },
    // 删除巡检任务内容提交记录
    async fetchDeleteTaskSubmitRecord({ commit, getters }, params) {
      const { deleteTaskSubmitRecord } = getters.getHttps
      const { data } = await deleteTaskSubmitRecord(params)
      return setStoreApiDate(data, commit)
    }
  }
}
