/**
 * 工作流管理
 */

const prefix = '/dseWeb'
export default [
  // 流程列表查询
  {
    name: 'definitionListRequest',
    url: `${prefix}/v1/op/wfp/definitionList`,
    method: 'get'
  },
  //查询任务节点的配置
  {
    name: 'getTaskConfRequest',
    url: `${prefix}/v1/op/wfp/getTaskConf`,
    method: 'get'
  },
  // 点击流程按钮执行操作
  {
    name: 'buttonClickRequest',
    url: `${prefix}/v1/op/wfp/buttonClick`,
    method: 'post'
  },
  // 根据任务ID查询提交记录
  {
    name: 'selectByTaskIdRequest',
    url: `${prefix}/v1/op/inspectTaskSubmitRecord/selectByTaskId`,
    method: 'get'
  },
  // 插入巡检任务内容提交记录
  {
    name: 'draftTaskRequest',
    url: `${prefix}/v1/op/inspectTaskSubmitRecord/draft`,
    method: 'post'
  },
  // 删除巡检任务内容提交记录
  {
    name: 'deleteTaskSubmitRecord',
    url: `${prefix}/v1/op/inspectTaskSubmitRecord/delete`,
    method: 'get'
  }
]
