/**
 * 人员站点起始位置管理
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询人员站点起始位置
    async fetchPointConfPage({ commit, getters }, params) {
      const { pointConfPage } = getters.getHttps
      const { data } = await pointConfPage(params)
      return setStoreApiDate(data, commit)
    },
    // 新增或修改人员站点起始位置
    async fetchInsertOrUpdatePointConf({ commit, getters }, params) {
      const { insertOrUpdatePointConf } = getters.getHttps
      const { data } = await insertOrUpdatePointConf(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个人员站点起始位置
    async fetchGetConfByUserId({ commit, getters }, params) {
      const { getConfByUserId } = getters.getHttps
      const { data } = await getConfByUserId(params)
      return setStoreApiDate(data, commit)
    },
    // 删除人员站点起始位置
    async fetchDeletePointConf({ commit, getters }, params) {
      const { deletePointConf } = getters.getHttps
      const { data } = await deletePointConf(params)
      return setStoreApiDate(data, commit)
    }
  }
}
