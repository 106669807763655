/**
 * 站点位置管理接口
 */

const prefix = '/dseWeb'
export default [
  // 查询列表（分页）
  { name: 'pointConfPage', url: `${prefix}/v1/op/pointConfPage`, method: 'post' },
  // 新增或修改
  {
    name: 'insertOrUpdatePointConf',
    url: `${prefix}/v1/op/insertOrUpdatePointConf`,
    method: 'post'
  },
  // 查询单个详情
  { name: 'getConfByUserId', url: `${prefix}/v1/op/getConfByUserId`, method: 'get' },
  // 删除单个详情
  { name: 'deletePointConf', url: `${prefix}/v1/op/deletePointConf`, method: 'get' }
]
